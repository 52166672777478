<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <ErrorFormField :msgArray="commonErrors" :msg-type="$data.result.status" />
        <div class="input-group mb-3">
          <span class="input-group-text" id="loginIconEmail"
            ><i class="far fa-envelope input-icon-swing"></i
          ></span>
          <input
            type="email"
            id="loginEmail"
            class="form-control"
            placeholder="Email Address or mobile number"
          />
        </div>
        <ErrorFormField :msgArray="emailErrors" v-if="emailErrors.length > 0" />
        <div class="input-group mb-3">
          <span class="input-group-text" id="loginIconPassword"
            ><i class="fa fa-lock input-icon-swing"></i
          ></span>
          <input type="password" class="form-control" id="loginPassword" placeholder="Password" />
        </div>
        <ErrorFormField :msgArray="passwordErrors" v-if="passwordErrors.length > 0" />
        <div class="mb-3 d-flex">
          <div class="text-end flex-fill">
            <router-link :to="{ name: 'ForgotPassword' }" class="text-end">
              Forgot password?</router-link
            >
          </div>
        </div>
        <div class="mb-3">
          <LoadingIcons v-if="ajaxLoad.normal" />
          <button
            class="btn btn-primary w-100"
            id="btnLoginModal"
            @click="btnLoginClick"
            type="submit"
            :disabled="ajaxLoad.normal"
            v-else
          >
            Log in
          </button>
        </div>

        <!--                    </form>-->
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
//eslint-disable-next-line
import Cookies from 'js-cookie';
// let bootstrap = require('bootstrap');

export default {
  name: 'PartnerLogin',
  components: { ErrorFormField, LoadingIcons },
  data: function () {
    return {
      commonErrors: [],
      emailErrors: [],
      passwordErrors: [],
      // formData : {email: "", password: ""},
      ajaxLoad: {
        normal: false,
      },
      result: {
        status: '',
      },
    };
  },
  methods: {
    btnLoginClick: async function () {
      this.$data.ajaxLoad.normal = true;
      let apiUrl = process.env.VUE_APP_API_URL;
      let data1 = {
        email: document.getElementById('loginEmail').value,
        password: document.getElementById('loginPassword').value,
      };

      const formData = Object.keys(data1)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
        .join('&');
      let self = this;
      await fetch(apiUrl + 'partner/login', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('test resData: ', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            self.$data.result.status = 'error';
            if (messages.common.length > 0) self.$data.commonErrors = messages.common;
            else self.$data.commonErrors = [];

            if (messages.email.length > 0) self.$data.emailErrors = messages.email;
            else self.$data.emailErrors = [];

            if (messages.password.length > 0) self.$data.passwordErrors = messages.password;
            else self.$data.passwordErrors.length = 0;
            console.log('self.$data ', self.$data);
            return false;
          }
          if (resData.status == 'warning') {
            self.$data.result.status = 'warning';
            self.$data.commonErrors = messages.common;
            return false;
          }

          Cookies.set('name', resData.name);
          Cookies.set('token', resData.token);
          Cookies.set('role', resData.role);
          Cookies.set('status', resData.partner_status);
          self.$store.commit('partnerLoginUpdate', resData);

          // self.$store.dispatch('loginUpdate', resData)
          console.log('success login');
          if (resData.partner_status == 2) self.$router.push('/partner/signup-agency');
          else if (resData.partner_status == 3) {
            self.$router.push('/partner/signup-agency-gst');
          } else if (resData.partner_status == 9) {
            self.$router.push('/');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      this.$data.ajaxLoad.normal = false;
    },
  },
  computed: {
    currentUrl: function () {
      return window.location.origin + this.$route.fullPath;
    },
  },
  mounted() {
    document.getElementById('loginEmail').value = '';
    document.getElementById('loginPassword').value = '';
  },
};
</script>

<style scoped></style>
